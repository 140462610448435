"use strict";

var _interopRequireDefault = require("/Users/tanglewang/htdocs/hsgx/anxinshouhu_h5/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _settings = _interopRequireDefault(require("./settings"));

//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'app',
  computed: {
    transitionName: function transitionName() {
      if (_settings.default.needPageTrans) {
        return this.$store.state.direction;
      }

      return '';
    }
  }
};
exports.default = _default;